@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}


// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1200px;

$navbarOffset: calc(100vh - 87px);

::ng-deep .mat-tab-header-pagination-chevron {
  border-color: white;
}
::ng-deep .mat-tab-header-pagination-disabled {
  border-color: rgba(#fafafa, 0.6);
}


// Small devices
@mixin sm {
  @media (max-width: #{$screen-sm-min}) {
      @content;
  }
}

// Medium devices
@mixin md {
  @media (max-width: #{$screen-md-min}) {
      @content;
  }
}

// Large devices
@mixin lg {
  @media (max-width: #{$screen-lg-min}) {
      @content;
  }
}

// Extra large devices
@mixin xl {
  @media (max-width: #{$screen-xl-min}) {
      @content;
  }
}

::ng-deep .redMenu {
  background-color: red !important;
  color: white !important;
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(
          #{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} *
            ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
        );
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}

$screenmin: 320px;
$screenmax: 1920px;
$min_font: 20px;
$max_font: 24px;

html {
  @include fluid-type($screenmin, $screenmax, $min_font, $max_font);
  background: #fff;
}

body {
  overflow-x: hidden;
}

//icons

// Fonts
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500");
@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@900&display=swap");

// @font-face {
//   font-family: "Material Icons";
//   font-style: normal;
//   font-weight: 400;
//   src: url(iconfont/MaterialIcons-Regular.eot); /* For IE6-8 */
//   src: local("Material Icons"), local("MaterialIcons-Regular"),
//     url(iconfont/MaterialIcons-Regular.woff2) format("woff2"), url(iconfont/MaterialIcons-Regular.woff) format("woff"),
//     url(iconfont/MaterialIcons-Regular.ttf) format("truetype");
// }

@font-face {
  font-family: "RocketRinder";
  src: url("/assets/fonts/RocketRinder-yV5d.ttf") format("truetype");
  font-style: "italic";
}

//CSS GEN
/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
*/

@import "~@angular/material/theming";
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

// Fonts
@import "https://fonts.googleapis.com/css?family=Material+Icons";
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500");

$fontConfig: (
  display-4: mat-typography-level(112px, 112px, 300, "Roboto", -0.0134em),
  display-3: mat-typography-level(56px, 56px, 400, "Roboto", -0.0089em),
  display-2: mat-typography-level(45px, 48px, 400, "Roboto", 0em),
  display-1: mat-typography-level(34px, 40px, 400, "Roboto", 0.0074em),
  headline: mat-typography-level(24px, 32px, 400, "Roboto", 0em),
  title: mat-typography-level(20px, 32px, 500, "Roboto", 0.0075em),
  subheading-2: mat-typography-level(16px, 28px, 400, "Roboto", 0.0094em),
  subheading-1: mat-typography-level(15px, 24px, 500, "Roboto", 0.0067em),
  body-2: mat-typography-level(14px, 24px, 500, "Roboto", 0.0179em),
  body-1: mat-typography-level(14px, 20px, 400, "Roboto", 0.0179em),
  button: mat-typography-level(14px, 14px, 500, "Roboto", 0.0893em),
  caption: mat-typography-level(12px, 20px, 400, "Roboto", 0.0333em),
  input: mat-typography-level(inherit, 1.125, 400, "Roboto", 1.5px),
);

// Foreground Elements

// Light Theme Text
$dark-text: #000000;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$mat-light-theme-foreground: (
  base: black,
  divider: $dark-dividers,
  dividers: $dark-dividers,
  disabled: $dark-disabled-text,
  disabled-button: rgba($dark-text, 0.26),
  disabled-text: $dark-disabled-text,
  elevation: black,
  secondary-text: $dark-accent-text,
  hint-text: $dark-disabled-text,
  accent-text: $dark-accent-text,
  icon: $dark-accent-text,
  icons: $dark-accent-text,
  text: $dark-primary-text,
  slider-min: $dark-primary-text,
  slider-off: rgba($dark-text, 0.26),
  slider-off-active: $dark-disabled-text,
);

// Dark Theme text
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$mat-dark-theme-foreground: (
  base: $light-text,
  divider: $light-dividers,
  dividers: $light-dividers,
  disabled: $light-disabled-text,
  disabled-button: rgba($light-text, 0.3),
  disabled-text: $light-disabled-text,
  elevation: black,
  hint-text: $light-disabled-text,
  secondary-text: $light-accent-text,
  accent-text: $light-accent-text,
  icon: $light-text,
  icons: $light-text,
  text: $light-text,
  slider-min: $light-text,
  slider-off: rgba($light-text, 0.3),
  slider-off-active: rgba($light-text, 0.3),
);

// Background config
// Light bg
$light-background: #fafafa;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-tooltip: lighten(#2c2c2c, 20%);
$dark-bg-alpha-4: rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12: rgba(#2c2c2c, 0.12);

$mat-light-theme-background: (
  background: $light-background,
  status-bar: $light-bg-darker-20,
  app-bar: $light-bg-darker-5,
  hover: $dark-bg-alpha-4,
  card: $light-bg-lighter-5,
  dialog: $light-bg-lighter-5,
  tooltip: $dark-bg-tooltip,
  disabled-button: $dark-bg-alpha-12,
  raised-button: $light-bg-lighter-5,
  focused-button: $dark-focused,
  selected-button: $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle: $light-bg-darker-10,
  unselected-chip: $light-bg-darker-10,
  disabled-list-option: $light-bg-darker-10,
);

// Dark bg
$dark-background: #2c2c2c;
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba(#fafafa, 0.04);
$light-bg-alpha-12: rgba(#fafafa, 0.12);

// Background palette for dark themes.
$mat-dark-theme-background: (
  background: $dark-background,
  status-bar: $dark-bg-lighter-20,
  app-bar: $dark-bg-lighter-5,
  hover: $light-bg-alpha-4,
  card: $dark-bg-lighter-5,
  dialog: $dark-bg-lighter-5,
  tooltip: $dark-bg-lighter-20,
  disabled-button: $light-bg-alpha-12,
  raised-button: $dark-bg-lighter-5,
  focused-button: $light-focused,
  selected-button: $dark-bg-lighter-20,
  selected-disabled-button: $dark-bg-lighter-30,
  disabled-button-toggle: $dark-bg-lighter-10,
  unselected-chip: $dark-bg-lighter-20,
  disabled-list-option: $dark-bg-lighter-10,
);

// Compute font config
@include mat-core($fontConfig);

// Theme Config

body {
  --primary-color: #d32f2f;
  --primary-lighter-color: #f2c1c1;
  --primary-darker-color: #c21d1d;
  --text-primary-color: #{$light-primary-text};
  --text-primary-lighter-color: #{$dark-primary-text};
  --text-primary-darker-color: #{$light-primary-text};
}

$mat-primary: (
  main: #d32f2f,
  lighter: #f2c1c1,
  darker: #c21d1d,
  200: #d32f2f,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-primary: mat-palette($mat-primary, main, lighter, darker);

body {
  --accent-color: #263238;
  --accent-lighter-color: #bec2c3;
  --accent-darker-color: #171f24;
  --text-accent-color: #{$light-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$light-primary-text};
}

$mat-accent: (
  main: #263238,
  lighter: #bec2c3,
  darker: #171f24,
  200: #263238,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-accent: mat-palette($mat-accent, main, lighter, darker);

body {
  --warn-color: #e09e00;
  --warn-lighter-color: #f6e2b3;
  --warn-darker-color: #d38200;
  --text-warn-color: #{$dark-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$dark-primary-text};
}

$mat-warn: (
  main: #e09e00,
  lighter: #f6e2b3,
  darker: #d38200,
  200: #e09e00,
  // For slide toggle,
  contrast:
    (
      main: $dark-primary-text,
      lighter: $dark-primary-text,
      darker: $dark-primary-text,
    ),
);
$theme-warn: mat-palette($mat-warn, main, lighter, darker);

$theme: mat-light-theme($theme-primary, $theme-accent, $theme-warn);
$altTheme: mat-dark-theme($theme-primary, $theme-accent, $theme-warn);

// Theme Init
@include angular-material-theme($theme);

.theme-alternate {
  @include angular-material-theme($altTheme);
}

.mat-form-field {
  color: $dark-primary-text;
}

// Specific component overrides, pieces that are not in line with the general theming

// Handle buttons appropriately, with respect to line-height
.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
  padding: 0 1.15em;
  margin: 0 0.65em;
  min-width: 3em;
  line-height: 36.4px;
}

.mat-standard-chip {
  padding: 0.5em 0.85em;
  min-height: 2.5em;
}

.material-icons {
  font-size: 24px;
  font-family: "Material Icons", "Material Icons";
  .mat-badge-content {
    font-family: "Roboto";
  }
}
*:focus {
  outline: none !important;
}
// ??CSS GEN END

//@at-root
// loading ANIMATION
.loading {
  border-radius: 30%;
  position: absolute;
  top: 42%;
  left: 38%;
  transform: translate(-505, -50%);
  width: 100px;
  height: 100px;
  background: rgb(0, 0, 0);
  background: radial-gradient(circle, rgba(0, 0, 0, 1) 0%, rgba(184, 0, 0, 1) 100%);
  border: 2px solid #ff0000;
  box-sizing: border-box;
  box-shadow: 0 0 0 5px #ff0000, 0 0 0 5px #000000;
  animation: rotate 4s ease-in-out infinite;
}

.loading:before,
.loading:after {
  content: "";
  position: absolute;
  left: -30px;
  top: -30px;
  right: -30px;
  bottom: -30px;
  border-radius: 50%;
  border: 4px solid #ff0000;
  animation: animate 4s ease-in-out infinite;
}

.loading:after {
  animation-delay: 0s;
}

@keyframes animate {
  25% {
    transform: scale(1.2) rotate(10deg);
  }
  50% {
    transform: scale(0.2) rotate(90deg);
  }
  75% {
    transform: scale(1.2) rotate(-90deg);
  }
}

@keyframes rotate {
  0% {
    background: rgb(255, 0, 0);
  }
  25% {
    transform: scale(1.2) rotate(10deg);
  }
  50% {
    transform: scale(0.2) rotate(90deg);

    background: rgb(0, 0, 0);
  }
  75% {
    transform: scale(1.2) rotate(-90deg);
  }
  100% {
    background: rgb(255, 0, 0);
  }
}

// <a href="https://www.freepik.com/photos/background">Background photo created by freepik - www.freepik.com</a>
